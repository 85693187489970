import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CampaignModel } from '@core/models/campaign.model';
import { CAMPAIGN_ENDPOINT } from '@core/tokens/campaign-endpoint.token';
import { HttpService } from '@core/services/http.service';

@Injectable({ providedIn: 'root' })
export class CampaignService {
  constructor(
    private readonly httpService: HttpService,
    @Inject(CAMPAIGN_ENDPOINT) private campaignEndpoint: string,
    private http: HttpClient,
  ) {}

  getCampaigns() {
    return this.http
      .get<CampaignModel[]>(this.campaignEndpoint, { observe: 'response' })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }
}
