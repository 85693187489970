import { Injectable } from '@angular/core';
import { HttpDataState } from '@core/types/http-data-state';
import { CampaignModel } from '@core/models/campaign.model';
import { NotificationService } from '@core/services/notification.service';
import { CampaignService } from '@core/services/campaign.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { filter, map, switchMap, takeUntil } from 'rxjs';
import { filterSuccess } from '@core/utils/filter-success';

interface CampaignStoreState {
  campaigns: HttpDataState<CampaignModel[]>;
}

@Injectable({ providedIn: 'root' })
export class CampaignStore extends ComponentStore<CampaignStoreState> {
  readonly campaigns$ = this.select(({ campaigns }) => campaigns);

  readonly closestCampaign$ = this.campaigns$.pipe(
    filterSuccess(),
    map((campaigns) => campaigns.data),
    filter((campaigns) => campaigns.length > 0),
    takeUntil(this.destroy$),
    map((campaigns) => {
      const currentDate = new Date(Date.now());
      const currentTimestamp = currentDate.getTime();
      let closestCampaign = campaigns[0];
      let timeDifference = Math.abs(
        currentTimestamp - new Date(closestCampaign.start_date).getTime(),
      );
      for (const campaign of campaigns) {
        const startDate = new Date(campaign.start_date);
        const newTimeDifference = Math.abs(
          currentTimestamp - startDate.getTime(),
        );
        if (newTimeDifference < timeDifference) {
          closestCampaign = campaign;
          timeDifference = newTimeDifference;
        }
      }
      return closestCampaign;
    }),
  );

  constructor(
    private readonly campaignService: CampaignService,
    private readonly notification: NotificationService,
  ) {
    super({ campaigns: { status: 'idle' } });
  }

  readonly loadCampaigns = this.effect((event$) =>
    event$.pipe(
      switchMap(() => {
        this.patchState({ campaigns: { status: 'loading' } });
        return this.campaignService.getCampaigns().pipe(
          tapResponse(
            (campaigns) => {
              campaigns.sort((a, b) => {
                const aDate = new Date(a.start_date);
                const bDate = new Date(b.start_date);
                return aDate.getTime() - bDate.getTime();
              });
              this.patchState({
                campaigns: { status: 'success', data: campaigns },
              });
            },
            (error: HttpErrorResponse) => {
              this.patchState({
                campaigns: { status: 'error', error: error },
              });
              this.notification.open({
                type: 'error',
                title: 'Failed to load campaigns',
                message:
                  'An unexpected error occurred when loading campaigns, please try again at a later time.',
              });
            },
          ),
        );
      }),
    ),
  );
}
